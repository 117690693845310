$base-dark-blue: #0e2455;
$base-dark-blue-1: #133217;
$base-dark-blue-0: #0e2455;

$color-bright-yellow: #FFD700;
$color-bright-orange: #ffc091;
$color-dark-purple: #260a2f;
$color-gold: #B87333;
$color-dark-gold: #382610;
$color-bright-blue: #ceddff;
$color-beige:  #F5F5DC ;
$color-dark-charcoal: #333739; //#21231d;
//$color-background-neutral: #16330014;
$color-white: #fff;
$color-black: #000;
$color-bright-green: #34f544; //#90EE90;
$color-forest-green: #133217;
$color-bright-pink: #ffd7ef;
$color-dark-maroon: #800020;
$color-content-primary: #90ee97;
$color-content-secondary: #e8ebe6;
$color-content-tertiary: #d0d5ce;
$color-content-link: #90ee90; //#90EE90;

$color-content-link-hover: #34f544; //#cdffad;
$color-content-link-active: #2c6627; //#ecffe0;
$color-interactive-primary: #34f544; //#90EE90
$color-interactive-primary-hover: #76c044; //#8bd35d;
$color-interactive-primary-active: #5ca036; // #7cc44f;

$color-interactive-primary-hover: #90EE90; //#cdffad
$color-interactive-primary-hover-hover: #ffffff;
$color-interactive-primary-hover-active: #aadb8b;
$color-interactive-primary-active: #2c6627;
$color-interactive-primary-active-hover: #d8eacc;
$color-interactive-primary-active-active: #c8dbbd;

$color-interactive-secondary: #cbd9c3;
$color-interactive-secondary-hover: #b7c5af;
$color-interactive-secondary-active: #a9b6a1;
$color-interactive-secondary-hover: #d9e3d3;
$color-interactive-secondary-hover-hover: #c5cfbf;
$color-interactive-secondary-hover-active: #b6c0b0;
$color-interactive-secondary-active: #e7ede3;
$color-interactive-secondary-active-hover: #d3d9cf;
$color-interactive-secondary-active-active: #c4cac0;
$color-interactive-accent: #34f544; //#90EE90;
$color-interactive-accent-hover: #76c044;
$color-interactive-accent-active: #5ca036;
$color-interactive-accent-hover: #90EE90; //#0d6efd
$color-interactive-accent-hover-hover: #b9ea9a;
$color-interactive-accent-hover-active: #aadb8b;
$color-interactive-accent-active: #2c6627;
$color-interactive-accent-active-hover: #d8eacc;
$color-interactive-accent-active-active: #c8dbbd;

$color-interactive-control: #133217;
$color-interactive-control-hover: #0c2300;
$color-interactive-control-active: #001a00;
$color-interactive-control-hover: #214d00;
$color-interactive-control-hover-hover: #103c00;
$color-interactive-control-hover-active: #093000;
$color-interactive-control-active: #2c6600;
$color-interactive-control-active-hover: #175400;
$color-interactive-control-active-active: #064700;
$color-interactive-contrast: #133217;
$color-interactive-contrast-hover: #214d00;
$color-interactive-contrast-hover-hover: #103c00;
$color-interactive-contrast-hover-active: #093000;
$color-interactive-contrast-active: #2c6600;
$color-interactive-contrast-active-hover: #175400;
$color-interactive-contrast-active-active: #064700;
$color-border-neutral: hsla(0, 0%, 100%, .2);
$color-border-overlay: hsla(0, 0%, 100%, .2);
$color-background-screen: #163308;
$color-background-screen-hover: hsla(0, 0%, 100%, .102);
$color-background-screen-active: hsla(0, 0%, 100%, .2);
$color-background-elevated: #223d0d;
$color-background-neutral: hsla(0, 0%, 100%, 0.1);
$color-background-neutral-hover: hsla(0, 0%, 100%, .2);
$color-background-neutral-active: hsla(0, 0%, 100%, .302);
$color-background-overlay: hsla(0, 0%, 100%, .2);
$color-sentiment-negative: #ffc4c2;
$color-sentiment-negative-hover: #ffdcdb;
$color-sentiment-negative-active: #ffebeb;
$color-sentiment-positive: #a0e5ae;
$color-sentiment-warning: #edd272;
$color-contrast: #133217;
$color-light: #fff;
$color-light-green-grey: #f2f5f3;
$color-dark: #133217;
$color-dark-trans: rgba(19, 50, 23, 0.6);
$color-contrast-overlay: #133217;
$color-contrast-theme: #fff;
$color-burgundian: #800020;
$color-burgundian2: #450303;
$color-cream: #FFFDD0;


$color-bootstrap-blue: #0d6efd;
$color-bootstrap-light-blue: #aebee7;


$color-green-light: rgb(229, 245, 230);
$color-green-medium: rgb(64, 103, 69);
$color-green-dark: rgb(65, 103, 64);

$color-grey-light: rgb(242, 245, 242);
$color-grey: rgb(216, 224, 216); // Объединенный серый цвет

$color-accent: rgb(127, 153, 128); // Объединенный акцентный цвет

$color-highlight: rgb(233, 252, 234);
$color-highlight-secondary: rgb(233, 252, 235);
$color-status: rgb(0, 160, 16);

$color-background-default: rgb(255, 255, 255);
$color-background-alternate: rgb(250, 251, 250);

$color-border-default: $color-grey;


$color-border-focus: #97a398;
$color-border-search-focus: #8efe86;
$color-background-default: #fff;

$color-border-dropdown: #98ab97;
$color-highlighted-background: #e9efe9;

$form-check-input-checked-bg-color: $color-dark;
$form-check-input-checked-border-color: $color-dark;


$padding-x-small: 8px;
$padding-small: 16px;
$padding-medium: 24px;
$padding-large: 32px;
$radius-full: 9999px;
$radius-small: 10px;
$radius-medium: 16px;
$radius-large: 24px;

$radius-custom: 0.7rem;

$size-4: 4px;
$size-8: 8px;
$size-10: 10px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-20: 20px;
$size-24: 24px;
$size-32: 32px;
$size-40: 40px;
$size-48: 48px;
$size-52: 52px;
$size-56: 56px;
$size-60: 60px;
$size-64: 64px;
$size-72: 72px;
$size-80: 80px;
$size-88: 88px;
$size-96: 96px;
$size-104: 104px;
$size-112: 112px;
$size-120: 120px;
$size-126: 126px;
$size-128: 128px;
$size-146: 146px;
$size-154: 154px;
$size-x-small: 24px;
$size-small: 32px;
$size-medium: 40px;
$size-large: 48px;
$size-x-large: 56px;
$size-2x-large: 72px;
$space-content-horizontal: 16px;
$space-small: 16px;
$space-medium: 32px;
$space-large: 40px;
$space-x-large: 56px;
$font-size-12: 0.75rem;
$font-size-14: 0.875rem;
$font-size-16: 1rem;
$font-size-18: 1.125rem;
$font-size-20: 1.25rem;
$font-size-22: 1.375rem;
$font-size-24: 1.5rem;
$font-size-26: 1.625rem;
$font-size-28: 1.75rem;
$font-size-30: 1.875rem;
$font-size-32: 2rem;

$letter-spacing-xxs: 0.005em;
$letter-spacing-xs: 0.01em;
$letter-spacing-sm: 0.0125em;
$letter-spacing-md: 0.015em;
$letter-spacing-lg: 0.02em;
$letter-spacing-xl: 0.025em;
$letter-spacing-negative-xxs: -0.005em;
$letter-spacing-negative-xs: -0.01em;
$letter-spacing-negative-sm: -0.0125em;
$letter-spacing-negative-md: -0.015em;
$letter-spacing-negative-lg: -0.02em;
$letter-spacing-negative-xl: -0.025em;
$letter-spacing-negative-xxl: -0.03em;
$letter-spacing-negative-xxxl: -0.04em;
$letter-spacing-negative-xxxxl: -0.05em;
$line-height-18: 1.125rem;
$line-height-20: 1.25rem;
$line-height-22: 1.375rem;
$line-height-24: 1.5rem;
$line-height-28: 1.75rem;
$line-height-30: 1.875rem;
$line-height-32: 2rem;
$line-height-34: 2.125rem;
$line-height-36: 2.25rem;
$line-height-42: 2.625rem;
$line-height-48: 3rem;
$line-height-title: 1.2;
$line-height-body: 1.5;
$line-height-control: 1.2;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 800;
$font-weight-black: 900;


$css-variables: (
        "base-dark-blue": $base-dark-blue,
        "base-dark-blue-0": $base-dark-blue-0,
        "color-bright-yellow": $color-bright-yellow,
        "color-bright-orange": $color-bright-orange,
        "color-dark-purple": $color-dark-purple,
        "color-dark-gold": $color-dark-gold,
        "color-bright-blue": $color-bright-blue,
        "color-dark-charcoal": $color-dark-charcoal,
        "color-white": $color-white,
        "color-black": $color-black,
        "color-bright-green": $color-bright-green,
        "color-forest-green": $color-forest-green,
        "color-bright-pink": $color-bright-pink,
        "color-dark-maroon": $color-dark-maroon,
        "color-content-primary": $color-content-primary,
        "color-content-secondary": $color-content-secondary,
        "color-content-tertiary": $color-content-tertiary,
        "color-content-link": $color-content-link,
        "color-content-link-hover": $color-content-link-hover,
        "color-content-link-active": $color-content-link-active,
        "color-interactive-primary": $color-interactive-primary,
        "color-interactive-primary-hover": $color-interactive-primary-hover,
        "color-interactive-primary-active": $color-interactive-primary-active,
        "color-interactive-primary-hover-hover": $color-interactive-primary-hover-hover,
        "color-interactive-primary-hover-active": $color-interactive-primary-hover-active,
        "color-interactive-primary-active-hover": $color-interactive-primary-active-hover,
        "color-interactive-primary-active-active": $color-interactive-primary-active-active,
        "color-interactive-secondary": $color-interactive-secondary,
        "color-interactive-secondary-hover": $color-interactive-secondary-hover,
        "color-interactive-secondary-active": $color-interactive-secondary-active,
        "color-interactive-secondary-hover-hover": $color-interactive-secondary-hover-hover,
        "color-interactive-secondary-hover-active": $color-interactive-secondary-hover-active,
        "color-interactive-secondary-active-hover": $color-interactive-secondary-active-hover,
        "color-interactive-secondary-active-active": $color-interactive-secondary-active-active,
        "color-interactive-accent": $color-interactive-accent,
        "color-interactive-accent-hover": $color-interactive-accent-hover,
        "color-interactive-accent-active": $color-interactive-accent-active,
        "color-interactive-accent-hover-hover": $color-interactive-accent-hover-hover,
        "color-interactive-accent-hover-active": $color-interactive-accent-hover-active,
        "color-interactive-accent-active-hover": $color-interactive-accent-active-hover,
        "color-interactive-accent-active-active": $color-interactive-accent-active-active,
        "color-interactive-control": $color-interactive-control,
        "color-interactive-control-hover": $color-interactive-control-hover,
        "color-interactive-control-active": $color-interactive-control-active,
        "color-interactive-control-hover-hover": $color-interactive-control-hover-hover,
        "color-interactive-control-hover-active": $color-interactive-control-hover-active,
        "color-interactive-control-active-hover": $color-interactive-control-active-hover,
        "color-interactive-control-active-active": $color-interactive-control-active-active,
        "color-interactive-contrast": $color-interactive-contrast,
        "color-interactive-contrast-hover": $color-interactive-contrast-hover,
        "color-interactive-contrast-hover-hover": $color-interactive-contrast-hover-hover,
        "color-interactive-contrast-hover-active": $color-interactive-contrast-hover-active,
        "color-interactive-contrast-active": $color-interactive-contrast-active,
        "color-interactive-contrast-active-hover": $color-interactive-contrast-active-hover,
        "color-interactive-contrast-active-active": $color-interactive-contrast-active-active,
        "color-border-neutral": $color-border-neutral,
        "color-border-overlay": $color-border-overlay,
        "color-background-screen": $color-background-screen,
        "color-background-screen-hover": $color-background-screen-hover,
        "color-background-screen-active": $color-background-screen-active,
        "color-background-elevated": $color-background-elevated,
        "color-background-neutral": $color-background-neutral,
        "color-background-neutral-hover": $color-background-neutral-hover,
        "color-background-neutral-active": $color-background-neutral-active,
        "color-background-overlay": $color-background-overlay,
        "color-sentiment-negative": $color-sentiment-negative,
        "color-sentiment-negative-hover": $color-sentiment-negative-hover,
        "color-sentiment-negative-active": $color-sentiment-negative-active,
        "color-sentiment-positive": $color-sentiment-positive,
        "color-sentiment-warning": $color-sentiment-warning,
        "color-contrast": $color-contrast,
        "color-light": $color-light,
        "color-dark": $color-dark,
        "color-contrast-overlay": $color-contrast-overlay,
        "color-contrast-theme": $color-contrast-theme,
        "color-bootstrap-blue": $color-bootstrap-blue,
        "color-bootstrap-light-blue": $color-bootstrap-light-blue
);


:root {
  @each $name, $value in $css-variables {
    --#{$name}: #{$value};
  }
}