@import "../../common/css/variables";


section {
  padding-bottom: $size-48;
  padding-top: $size-48;

  @media(min-width: 576px) {
    padding-bottom: $size-72;
    padding-top: $size-72;
  }

  @media(min-width: 992px) {
    padding-bottom: $size-96;
    padding-top: $size-96
  }

}

.hero {
  padding-top: $size-16;

  @media(min-width: 576px) {
    padding-top: 0;
  }
}

.hero, .features {
  background-color: $color-dark;
  color: $color-content-primary;
}

.hero-header-container2 {
  margin-bottom: $size-32;
  @media (min-width: 992px) {
    margin-bottom: $size-40;
  }
}

.hero-paragraph-container {
  margin-bottom: $size-48;
  @media (min-width: 992px) {
    margin-bottom: $size-64;
  }
}

.hero-header {
  font-size: 50px;
  line-height: 1.2;
  letter-spacing: 0;
  margin-bottom: $size-32;

  font-weight: $font-weight-semi-bold;
  font-size: clamp(2.375rem, calc((38 / 16 * 1rem) + (1vw - .2rem) * (100 * ((50 / 16) - (38 / 16)) / (26.875 - 20))), 3.125rem);

  @media (min-width: 992px) {
    margin-bottom: $size-40;
    font-size: $size-120;
    line-height: 1.2;
    letter-spacing: -.05em;
    letter-spacing: $letter-spacing-negative-xxxxl;
    font-weight: $font-weight-semi-bold;
    font-size: clamp(3.125rem, calc((50 / 16 * 1rem) + (1vw - .62rem) * (100 * ((96 / 16) - (50 / 16)) / (76.25 - 62))), 6rem);
  }
}

.hero-paragraph {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -.01em;
  margin-bottom: 24px;
  margin-bottom: $size-24;

  font-size: clamp(1.125rem, calc((18 / 16 * 1rem) + (1vw - .2rem) * (100 * ((20 / 16) - (18 / 16)) / (26.875 - 20))), 1.25rem);

  @media (min-width: 768px) {
    margin-bottom: 32px;
    margin-bottom: $size-32;
  }


  @media (min-width: 992px) {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: -.015em;

    font-size: clamp(1.25rem, calc((20 / 16 * 1rem) + (1vw - .62rem) * (100 * ((24 / 16) - (20 / 16)) / (76.25 - 62))), 1.5rem);
  }
}



.cta:hover {
  background-color: var(--color-content-link-hover);
}

.feature-item, .testimonial-item {
  margin-bottom: 20px;
}


video {
  display: block;
}

video {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

:disabled {
  cursor: default !important;
  filter: grayscale(1);
  opacity: .45;
}


.hero-media-container .media__bg {
  width: 100%;
  border-radius: 0.7rem;
}

.hero-media-wrapper {

  @media (min-width: 992px) {
    transform: translateY(-28%);
    margin-bottom: -15.68%;
  }
}


.hero-media-container {
  position: relative;

  @media (min-width: 992px) {
    transform-origin: bottom right;
    transition: all .15s cubic-bezier(.25, .46, .45, .94);
  }

  @media (max-width: 991px) {

    transform: none !important;
    margin-top: $size-48;

  }
}


.step-slider-wrapper {
  overflow: hidden;
}

.step-slider-grid {
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-columns: 57% 43%;
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-areas: "t i" "c i";
  }

  @media (min-width: 992px) {
    grid-template-columns: 60% 40%;
  }

  @media (min-width: 1600px) {
    grid-template-columns: 70% 30%;
  }
}

.step-slider-header-area {
  grid-area: t;
  display: flex;
  align-items: flex-end;
  margin-right: 16px;
  margin-right: $size-16;
}


.step-slider-header {
  font-size: 50px;
  line-height: .85;
  letter-spacing: 0;
  margin-bottom: $size-32;
  font-weight: $font-weight-black;
  text-transform: uppercase;
  font-size: clamp(2.25rem, calc((36 / 16 * 1rem) + (1vw - .2rem) * (100 * ((50 / 16) - (36 / 16)) / (26.875 - 20))), 3.125rem);

  @media(min-width: 768px) {
    font-size: clamp(2.75rem, calc((44 / 16 * 1rem) + (1vw - .5rem) * (100 * ((64 / 16) - (44 / 16)) / (76.25 - 50))), 4rem);
  }

  @media(min-width: 992px) {
    font-size: 96px;
    font-size: $size-96;
    line-height: .85;
    letter-spacing: 0;
    margin-bottom: $size-32;
    font-weight: $font-weight-black;
    font-size: clamp(3.125rem, calc((50 / 16 * 1rem) + (1vw - .62rem) * (100 * ((86 / 16) - (50 / 16)) / (76.25 - 62))), 4.375rem);
  }

  @media(min-width: 1200px) {
    font-size: clamp(4.375rem, calc((70 / 16 * 1rem) + (1vw - .7625rem) * (100 * ((88 / 16) - (70 / 16)) / (120 - 76.25))), 5.5rem);
  }

}

.step-slider-header:lang(ru),
.step-slider-header:lang(kk) {

  font-size: clamp(1rem, calc(1rem + (1vw - .1rem) * (100 * ((27 / 16) - (16 / 16)) / (36 - 10))), 1.7rem);

  @media(min-width: 368px) {
    font-size: clamp(2rem, calc(2rem + (1vw - .2rem) * (100 * ((44 / 16) - (32 / 16)) / (37 - 28))), 2.8rem);
  }

  @media(min-width: 768px) {
    font-size: clamp(2.5rem, calc(2.5rem + (1vw - 0.5rem) * (100 * ((56 / 16) - (40 / 16)) / (99 - 50))), 3.5rem);
  }


  @media(min-width: 992px) {
    font-size: clamp(2.625rem, calc(2.625rem + (1vw - 0.62rem) * (100 * ((62 / 16) - (42 / 16)) / (120 - 62))), 3.875rem);
  }

  @media(min-width: 1200px) {
    font-size: clamp(3.75rem, calc(3.75rem + (1vw - 0.7625rem) * (100 * ((70 / 16) - (60 / 16)) / (120 - 76.25))), 5rem);
  }
}

.step-slider-header:lang(kk) {
  line-height: .98;
}


.step-slider-controls-container {
  order: 1;
  grid-area: c;
  padding-top: $size-60;
}


.step-slider-control-btn {
  border: none;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $color-dark;
  color: $color-content-link;
  margin-right: 26px;
  transition: all .3s;
  width: 48px;
  height: 48px;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }

  &:hover:not(:disabled) {

    background-color: $color-content-link;
    color: $color-dark;

  }

  @media (min-width: 992px) {
    height: 78px;
    width: 78px;
  }

  > .tw-icon {
    > svg {
      width: 24px;
      height: 24px;

      @media (min-width: 992px) {
        width: 42px;
        height: 42px;

      }

    }
  }


}


.step-slider-cards-area {
  grid-area: i;
}

.step-slider-cards {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}


.step-slider-card {
  border-radius: $radius-custom;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  color: $color-dark;
  background-color: $color-content-link;
  padding: clamp(0.75rem, calc(0.75rem + (1vw - 0.225rem) * 0.19230769), 0.9375rem);
  width: 268px;
  margin-right: 14px;
  margin-right: $size-14;
  position: relative;
  overflow: hidden;
  min-height: 345px;

  transition-duration: 1s;
  transition-property: transform, opacity;

  @media (min-width: 768px) {
    width: 332px;
    min-height: 450px;
  }

  @media (min-width: 992px) {
    width: 332px;
    min-height: 680px;
    width: 487px;
    margin-right: 24px;
    margin-right: $size-24;
  }

}

.step-slider-card-dark-green {
  color: $color-content-link;
  background-color: $color-dark;
}


.step-slider-card-purple {
  color: $color-bright-orange;
  background-color: $color-dark-purple;
}


.step-slider-card-blue {
  color: $color-dark-purple;
  background-color: $color-bright-blue;
}

.step-slider-card-image {
  display: block;
  max-width: 100%;
  height: auto;
  opacity: 0.26;

  //filter: blur(3.5px);
}

:root {
  --mw-space-1: clamp(0.25rem, calc(0.25rem + (1vw - 0.225rem) * 0.06410256), 0.3125rem);
  --mw-space-2: clamp(0.5rem, calc(0.5rem + (1vw - 0.225rem) * 0.12820513), 0.625rem);
  --mw-space-3: clamp(0.75rem, calc(0.75rem + (1vw - 0.225rem) * 0.19230769), 0.9375rem);
  --mw-space-4: clamp(1rem, calc(1rem + (1vw - 0.225rem) * 0.25641026), 1.25rem);
  --mw-space-6: clamp(1.5rem, calc(1.5rem + (1vw - 0.225rem) * 0.38461538), 1.875rem);
  --mw-space-8: clamp(2rem, calc(2rem + (1vw - 0.225rem) * 0.51282051), 2.5rem);
  --mw-space-12: clamp(3rem, calc(3rem + (1vw - 0.225rem) * 0.76923077), 3.75rem);
  --mw-space-16: clamp(4rem, calc(4rem + (1vw - 0.225rem) * 1.02564103), 5rem);
  --mw-space-24: clamp(6rem, calc(6rem + (1vw - 0.225rem) * 1.53846154), 7.5rem);
  --mw-space-32: clamp(8rem, calc(8rem + (1vw - 0.225rem) * 2.05128205), 10rem);
}


.step-slider-card-item-exit-done {
  transform: scale3d(.3, .3, .3);
  opacity: 0;
}

.step-slider-card-body {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding: 0 var(--mw-space-1) var(--mw-space-6);
  border-radius: $radius-custom;
  grid-gap: var(--mw-space-4);
  gap: var(--mw-space-4);


  @media (min-width: 768px) {
    padding: 0 var(--mw-space-4) var(--mw-space-6);
  }

  @media (min-width: 992px) {
    padding: 0 var(--mw-space-6) var(--mw-space-6);
  }
}

.step-slider-card-body-with-image {
  @media (min-width: 992px) {
    padding: var(--mw-space-8) var(--mw-space-12) var(--mw-space-6);
    background: 0 0;
  }
}


.step-slider-card-body-no-image {
  @media (min-width: 992px) {
    padding: 0 var(--mw-space-6) var(--mw-space-6);
    background: 0 0
  }
}


.step-slider-card-title, .step-slider-card-description {
  font-size: $size-20;
  line-height: 28px;
  letter-spacing: -.015em;
  font-weight: $font-weight-semi-bold;

  @media (min-width: 768px) {
    font-size: $size-24;
    line-height: 34px;
  }

  @media (min-width: 992px) {
    font-size: $size-40;
    line-height: 44px;
    //padding-bottom: $size-96;

  }
}

.step-slider-card-title {
  text-transform: uppercase;
  font-weight: 800;
  margin-top: 50%;
}

.step-slider-card-image-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}


.step-slider-card-visible {
  opacity: 1;
  transform: scaleX(1);
}


.step-slider-card-item-enter {
  opacity: 0;
  transform: scale3d(.3, .3, .3)
}

.step-slider-card-item-enter-active, .step-slider-card-item-enter-done {
  opacity: 1;
  transform: scaleX(1)
}

.step-slider-card-item-exit-active, .step-slider-card-item-exit-done, .step-slider-card-item-exit {
  opacity: 0
}

.step-slider-card-item-exit-active, .step-slider-card-item-exit-done {
  transform: scale3d(.3, .3, .3)
}

.step-slider-card-item-exit-done:first-child + .step-slider-card, .step-slider-card-item-exit-done:first-child + .step-slider-card + .step-slider-card, .step-slider-card-item-exit-done:first-child + .step-slider-card + .step-slider-card + .step-slider-card {
  transform: translateX(-100%)
}

.step-slider-card-item-exit-done:first-child + .step-slider-card + .step-slider-card + .step-slider-card.step-slider-card-item-exit-done, .step-slider-card-item-exit-done:first-child + .step-slider-card + .step-slider-card.step-slider-card-item-exit-done, .step-slider-card-item-exit-done:first-child + .step-slider-card.step-slider-card-item-exit-done {
  transform: translateX(-100%) scale3d(.3, .3, .3)
}

.step-slider-card-item-exit-done:first-child + .step-slider-card-item-exit-done + .step-slider-card, .step-slider-card-item-exit-done:first-child + .step-slider-card-item-exit-done + .step-slider-card + .step-slider-card {
  transform: translateX(-200%)
}

.step-slider-card-item-exit-done:first-child + .step-slider-card-item-exit-done + .step-slider-card + .step-slider-card.step-slider-card-item-exit-done, .step-slider-card-item-exit-done:first-child + .step-slider-card-item-exit-done + .step-slider-card.step-slider-card-item-exit-done {
  transform: translateX(-200%) scale3d(.3, .3, .3)
}

.step-slider-card-item-exit-done:first-child + .step-slider-card-item-exit-done + .step-slider-card-item-exit-done + .step-slider-card {
  transform: translateX(-300%)
}

.step-slider-card-item-exit-done:first-child + .step-slider-card-item-exit-done + .step-slider-card-item-exit-done + .step-slider-card.step-slider-card-item-exit-done {

  transform: translateX(-300%) scale3d(.3, .3, .3)
}


.mw-body-1-bold {
  margin-bottom: 8px;
  margin-bottom: var(--size-8);
  font-weight: 600;
  font-weight: var(--font-weight-semi-bold);

  line-height: 26px;
  letter-spacing: .005em;
  font-size: clamp(1rem, calc((16 / 16 * 1rem) + (1vw - .2rem) * (100 * ((18 / 16) - (16 / 16)) / (26.875 - 20))), 1.125rem);
  @media(min-width: 992px) {
    line-height: 28px;
    font-size: clamp(1.125rem, calc((18 / 16 * 1rem) + (1vw - .62rem) * (100 * ((20 / 16) - (18 / 16)) / (76.25 - 62))), 1.25rem)
  }

}


.tiles-header {
  font-size: 37px;
  line-height: 41px;
  letter-spacing: -.03em;
  margin-bottom: $size-72;
  font-weight: $font-weight-semi-bold;
  font-size: max(1.875rem, min(calc(.60227rem + 6.36364vw), 2.3125rem));

  @media(min-width: 992px) {
    font-size: 53px;
    line-height: 57px;
    letter-spacing: -.03em;
    font-size: max(2.3125rem, min(calc(.95285rem + 2.19298vw), 2.625rem));
  }

  @media(min-width: 1200px) {
    font-size: max(2.625rem, min(calc(1.42679rem + 1.57143vw), 3.3125rem))
  }
}


.tiles-container {
  display: grid;
  margin: 0;
  padding: 0;
  grid-gap: $size-16;
  grid-template-columns: 1fr;

  @media(min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);

  }
}


.tile-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-sizing: border-box;
  border-radius: $radius-custom;
  min-width: 100%;
  padding: 120px $size-24 $size-32;
  position: relative;
  overflow: hidden;

  @media(min-width: 768px) {
    padding: $size-40;
    min-height: 482px;

    &:first-of-type, &:last-of-type {
      grid-column: span 2
    }

    &:first-of-type .tile-description-container, &:last-of-type .tile-description-container {
      max-width: 75%
    }

  }

}


.tile-image {
  display: none;
  position: absolute;
  height: 400px;
  width: 400px;
  top: -8px;
  right: -8px;
  z-index: 1;

  @media(min-width: 768px) {
    display: block
  }
}


.tile-description-container {
  display: flex;
  flex-direction: column;
  gap: $size-24;
  max-width: 100%;
  z-index: 2
}


.tile-title, .price-header, .testimonial-header {

  font-size: 46px;
  line-height: .85;
  letter-spacing: 0;
  margin-bottom: $size-32;
  font-weight: $font-weight-black;
  font-size: max(2rem, min(calc(-.54545rem + 12.72727vw), 2.875rem));

  @media(min-width: 992px) {

    font-size: $size-64;
    line-height: .85;
    letter-spacing: 0;
    margin-bottom: $size-32;
    font-size: max(2.875rem, min(calc(.1557rem + 4.38596vw), 3.5rem))

  }

  @media(min-width: 1200px) {
    font-size: max(3.5rem, min(calc(2.62857rem + 1.14286vw), 4rem))
  }
}

.price-header {
  margin-bottom: 72px;
}

.testimonial-header {
  margin-bottom: 46px;
}

.tile-description {
  &:last-child {
    margin-bottom: 0
  }
}


.theme-bg-blue .tile-description * {
  color: var(--color-dark-charcoal)
}

.Tile_pinkTheme__WvgSG .tile-description * {
  color: #5d7079;
  color: var(--color-content-secondary)
}

.theme-bg-blue .tile-description a:hover, .Tile_pinkTheme__WvgSG .tile-description a:hover {
  color: #c9cbce;
  color: var(--color-interactive-secondary)
}

.theme-bg-gold .tile-description *, .theme-bg-purple .tile-description * {
  color: #f3f5f1
}

.theme-bg-gold .tile-description a:hover, .theme-bg-purple .tile-description a:hover {
  color: #b9bab7
}


.theme-bg-dark-green .tile-description *, .theme-bg-purple .tile-description * {
  color: $color-content-secondary;
}


.tile-description * {
  font-weight: $font-weight-regular
}


.tile-title.tile-title-color {
  word-wrap: break-word;
}

.theme-bg-blue {
  background-color: $color-bright-blue;
}

.theme-bg-purple {
  background-color: $color-dark-purple;
}

.theme-bg-gold {
  background-color: $color-dark-gold;
}


.theme-bg-green, .bg-green {
  background-color: $color-content-link;
}


.theme-bg-dark-green {
  background-color: $color-forest-green;
}


.theme-bg-purple .tile-title-color {
  color: $color-bright-orange;
}

.theme-bg-blue .tile-title-color {
  color: $color-dark-purple;
}

.theme-bg-gold .tile-title-color {
  color: $color-beige;
}

.theme-bg-green .tile-title-color {
  color: $color-forest-green;
}


.theme-bg-dark-green .tile-title-color {
  color: $color-content-link;
}


.mw-row {
  --column-gap: var(--mw-space-3);
  --grid-columns: 12;
  --row-gap: var(--mw-space-4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  grid-gap: var(--row-gap) var(--column-gap);
  gap: var(--row-gap) var(--column-gap);
  width: 100%;

  @media(min-width: 768px) {
    --column-gap: var(--mw-space-4);
    --grid-columns: 12

  }
  @media(min-width: 1200px) {
    --column-gap: var(--mw-space-6)
  }
}

@media(min-width: 768px) {
  .mw-row {
    --column-gap: var(--mw-space-4);
    --grid-columns: 12
  }
}


.article-container:first-of-type {
  //margin-bottom: var(--mw-space-8)
}

.article-container {
  margin-bottom: clamp(4rem, calc(4rem + (1vw - 0.225rem) * 1.02564103), 5rem);
  @media (min-width: 768px) {
    margin-bottom: clamp(2rem, calc(2rem + (1vw - 0.225rem) * 0.51282051), 2.5rem);
  }
}

.article-container:last-of-type {
  margin-bottom: 0;
}


.mw-cluster--reverse {
  grid-auto-flow: dense
}


.mw-cluster-md-2 {
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, var(--unit));
  }
}


.article-module-content {
  container-type: inline-size
}


.article-module-body {


  @media(min-width: 768px) {
    max-width: 480px;
  }
}

.mw-heading-4 {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -.015em;
  margin-bottom: $size-24;
  font-weight: $font-weight-semi-bold;
  font-size: clamp(1.3125rem, calc((21 / 16 * 1rem) + (1vw - .2rem) * (100 * ((24 / 16) - (21 / 16)) / (26.875 - 20))), 1.5rem);

  @media(min-width: 768px) {
    margin-bottom: var(--mw-space-4)
  }

  @media(min-width: 992px) {

    font-size: 30px;
    line-height: 34px;
    letter-spacing: -.025em;
    margin-bottom: $size-24;
    font-size: clamp(1.5rem, calc((24 / 16 * 1rem) + (1vw - .62rem) * (100 * ((28 / 16) - (24 / 16)) / (76.25 - 62))), 1.75rem)

  }

  @media(min-width: 1200px) {
    font-size: clamp(1.75rem, calc((28 / 16 * 1rem) + (1vw - .7625rem) * (100 * ((30 / 16) - (28 / 16)) / (120 - 76.25))), 1.875rem)
  }
}

.mw-body-2 {
  margin-bottom: 8px;
  margin-bottom: var(--size-8);
  font-weight: 400;
  font-weight: var(--font-weight-regular);
  line-height: 26px;
  letter-spacing: .005em;
  font-size: clamp(.875rem, calc((14 / 16 * 1rem) + (1vw - .2rem) * (100 * ((16 / 16) - (14 / 16)) / (26.875 - 20))), 1rem);

  @media(min-width: 992px) {

    line-height: 26px;
    letter-spacing: .01em;
    font-size: clamp(1rem, calc((16 / 16 * 1rem) + (1vw - .62rem) * (100 * ((18 / 16) - (16 / 16)) / (76.25 - 62))), 1.125rem)

  }

}


.article-module-button-container {
  margin-top: var(--mw-space-8);

  @media(min-width: 768px) {
    max-width: 480px;

    text-align: left

  }
}

.article-image-container {
  position: relative;
  margin-right: 0;

  @media(min-width: 768px) {

    margin-right: 100px;

  }
}

.article-top-offset {
  @media(min-width: 1200px) {
    transform: translateY(calc(-1 * var(--mw-space-24)));
    margin-bottom: calc(-1 * var(--mw-space-24))
  }
}

.mw-section--contained .mw-row {
  align-items: center
}

.mw-row {
  --column-gap: var(--mw-space-3);
  --grid-columns: 12;
  --row-gap: var(--mw-space-4);
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: var(--row-gap) var(--column-gap);
  gap: var(--row-gap) var(--column-gap);
  width: 100%;

  @media(min-width: 768px) {
    --column-gap: var(--mw-space-4);
    --grid-columns: 12
  }

  @media(min-width: 1200px) {
    --column-gap: var(--mw-space-6)
  }
}


[class*=" mw-cluster-"] {
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem);
  column-gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem);
  grid-column-gap: var(--mw-space-6);
  column-gap: var(--mw-space-6);
  grid-row-gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem);
  row-gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem);
  grid-row-gap: var(--mw-space-8);
  row-gap: var(--mw-space-8);
  --unit: 1fr;
}

@media (min-width: 768px) {
  .mw-cluster-md-2 {
    grid-template-columns: repeat(2, var(--unit));
  }
}

.mw-cluster--reverse {
  grid-auto-flow: dense;
}

.mw-cluster--reverse > :first-child {
  grid-column-end: -1;
}

[class*=" mw-col-"], [class^=mw-col-] {
  --column-span: 12;
  width: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-span) / var(--grid-columns) + (var(--column-span) - 1) * var(--column-gap));
}

.mw-col-xs-12 {
  --column-span: 12;
}

@media (min-width: 1440px) {
  .mw-col-xl-10 {
    --column-span: 10;
  }
}

//[class*=" mw-col-"][class*=-offset-], [class^=mw-col-][class*=-offset-] {
//margin-left: calc((100% - (var(--grid-columns) - 1) * var(--column-gap)) * var(--column-offset) / var(--grid-columns) + var(--column-offset) * var(--column-gap));
//}

@media (min-width: 1440px) {
  .mw-col-xl-offset-1 {
    --column-offset: 1;
  }
}

.mw-row > [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.mw-row > [class*=col-xl] {
  margin-bottom: 0;
}

.np-btn {
  position: relative;
}

a {
  border-radius: 2px;
}

.np-theme-personal .mw-heading-4 {
  margin-bottom: var(--mw-space-3);
}

@media (min-width: 768px) {
  .np-theme-personal .mw-heading-4 {
    margin-bottom: var(--mw-space-4);
  }
}

._container_b2oqd_1 {
  position: relative;
}

._float_b2oqd_5 {
  --layered-offset: 20px;
  position: absolute;
  margin: var(--layered-offset);
}

._float_b2oqd_5._middle-center_b2oqd_58 {
  top: 50%;
  left: 50%;
  margin: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

._float_b2oqd_5._fill_b2oqd_72 {
  width: calc(100% - var(--layered-offset) * 2);
}

/*! CSS Used from: https://wise.com/static-assets/app/_next/static/css/3cb735bb69f037e5.css */
.Module_content__xL6gw {
  container-type: inline-size;
}

.Module_body__TNflR {
  max-width: 480px;
}

.Module_buttonContainer__0McZv {
  margin-top: var(--mw-space-8);
}

@media (min-width: 768px) {
  .Module_buttonContainer__0McZv {
    text-align: left;
  }
}

/*! CSS Used from: Embedded */
[class*=col-] {
  position: relative;
  min-height: 1px;
  padding-right: 12px;
  padding-left: 12px;
}

@media (max-width: 575px) {
  [class*=col-] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

[class*=col-xs] {
  float: left;
}

.article-image {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border-radius: $radius-custom;
}

hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .102);
  border-top: 1px solid var(--color-border-neutral);
  margin: 16px auto;
  margin: var(--size-16) auto;
}

@media (min-width: 576px) {
  hr {
    margin: 24px auto;
    margin: var(--size-24) auto;
  }
}

@media (min-width: 992px) {
  hr {
    margin: 32px auto;
    margin: var(--size-32) auto;
  }
}


.mw-gap-0 {
  grid-gap: 0 !important;
  gap: 0 !important
}

.mw-gap-1 {
  grid-gap: clamp(.25rem, calc(.25rem + (1vw - .225rem) * .06410256), .3125rem) !important;
  gap: clamp(.25rem, calc(.25rem + (1vw - .225rem) * .06410256), .3125rem) !important;
  grid-gap: var(--mw-space-1) !important;
  gap: var(--mw-space-1) !important
}

.mw-gap-2 {
  grid-gap: clamp(.5rem, calc(.5rem + (1vw - .225rem) * .12820513), .625rem) !important;
  gap: clamp(.5rem, calc(.5rem + (1vw - .225rem) * .12820513), .625rem) !important;
  grid-gap: var(--mw-space-2) !important;
  gap: var(--mw-space-2) !important
}

.mw-gap-3 {
  grid-gap: clamp(.75rem, calc(.75rem + (1vw - .225rem) * .19230769), .9375rem) !important;
  gap: clamp(.75rem, calc(.75rem + (1vw - .225rem) * .19230769), .9375rem) !important;
  grid-gap: var(--mw-space-3) !important;
  gap: var(--mw-space-3) !important
}

.mw-gap-4 {
  grid-gap: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem) !important;
  gap: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem) !important;
  grid-gap: var(--mw-space-4) !important;
  gap: var(--mw-space-4) !important
}

.mw-gap-6 {
  grid-gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem) !important;
  gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem) !important;
  grid-gap: var(--mw-space-6) !important;
  gap: var(--mw-space-6) !important
}

.mw-gap-8 {
  grid-gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem) !important;
  gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem) !important;
  grid-gap: var(--mw-space-8) !important;
  gap: var(--mw-space-8) !important
}

.mw-gap-12 {
  grid-gap: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem) !important;
  gap: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem) !important;
  grid-gap: var(--mw-space-12) !important;
  gap: var(--mw-space-12) !important
}

.mw-gap-16 {
  grid-gap: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem) !important;
  gap: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem) !important;
  grid-gap: var(--mw-space-16) !important;
  gap: var(--mw-space-16) !important
}

.mw-gap-24 {
  grid-gap: clamp(6rem, calc(6rem + (1vw - .225rem) * 1.53846154), 7.5rem) !important;
  gap: clamp(6rem, calc(6rem + (1vw - .225rem) * 1.53846154), 7.5rem) !important;
  grid-gap: var(--mw-space-24) !important;
  gap: var(--mw-space-24) !important
}

.mw-gap-32 {
  grid-gap: clamp(8rem, calc(8rem + (1vw - .225rem) * 2.05128205), 10rem) !important;
  gap: clamp(8rem, calc(8rem + (1vw - .225rem) * 2.05128205), 10rem) !important;
  grid-gap: var(--mw-space-32) !important;
  gap: var(--mw-space-32) !important
}

@media(min-width: 768px) {
  .mw-gap-md-0 {
    grid-gap: 0 !important;
    gap: 0 !important
  }

  .mw-gap-md-1 {
    grid-gap: clamp(.25rem, calc(.25rem + (1vw - .225rem) * .06410256), .3125rem) !important;
    gap: clamp(.25rem, calc(.25rem + (1vw - .225rem) * .06410256), .3125rem) !important;
    grid-gap: var(--mw-space-1) !important;
    gap: var(--mw-space-1) !important
  }

  .mw-gap-md-2 {
    grid-gap: clamp(.5rem, calc(.5rem + (1vw - .225rem) * .12820513), .625rem) !important;
    gap: clamp(.5rem, calc(.5rem + (1vw - .225rem) * .12820513), .625rem) !important;
    grid-gap: var(--mw-space-2) !important;
    gap: var(--mw-space-2) !important
  }

  .mw-gap-md-3 {
    grid-gap: clamp(.75rem, calc(.75rem + (1vw - .225rem) * .19230769), .9375rem) !important;
    gap: clamp(.75rem, calc(.75rem + (1vw - .225rem) * .19230769), .9375rem) !important;
    grid-gap: var(--mw-space-3) !important;
    gap: var(--mw-space-3) !important
  }

  .mw-gap-md-4 {
    grid-gap: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem) !important;
    gap: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem) !important;
    grid-gap: var(--mw-space-4) !important;
    gap: var(--mw-space-4) !important
  }

  .mw-gap-md-6 {
    grid-gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem) !important;
    gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem) !important;
    grid-gap: var(--mw-space-6) !important;
    gap: var(--mw-space-6) !important
  }

  .mw-gap-md-8 {
    grid-gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem) !important;
    gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem) !important;
    grid-gap: var(--mw-space-8) !important;
    gap: var(--mw-space-8) !important
  }

  .mw-gap-md-12 {
    grid-gap: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem) !important;
    gap: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem) !important;
    grid-gap: var(--mw-space-12) !important;
    gap: var(--mw-space-12) !important
  }

  .mw-gap-md-16 {
    grid-gap: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem) !important;
    gap: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem) !important;
    grid-gap: var(--mw-space-16) !important;
    gap: var(--mw-space-16) !important
  }

  .mw-gap-md-24 {
    grid-gap: clamp(6rem, calc(6rem + (1vw - .225rem) * 1.53846154), 7.5rem) !important;
    gap: clamp(6rem, calc(6rem + (1vw - .225rem) * 1.53846154), 7.5rem) !important;
    grid-gap: var(--mw-space-24) !important;
    gap: var(--mw-space-24) !important
  }

  .mw-gap-md-32 {
    grid-gap: clamp(8rem, calc(8rem + (1vw - .225rem) * 2.05128205), 10rem) !important;
    gap: clamp(8rem, calc(8rem + (1vw - .225rem) * 2.05128205), 10rem) !important;
    grid-gap: var(--mw-space-32) !important;
    gap: var(--mw-space-32) !important
  }
}

@media(min-width: 992px) {
  .mw-gap-lg-0 {
    grid-gap: 0 !important;
    gap: 0 !important
  }

  .mw-gap-lg-1 {
    grid-gap: clamp(.25rem, calc(.25rem + (1vw - .225rem) * .06410256), .3125rem) !important;
    gap: clamp(.25rem, calc(.25rem + (1vw - .225rem) * .06410256), .3125rem) !important;
    grid-gap: var(--mw-space-1) !important;
    gap: var(--mw-space-1) !important
  }

  .mw-gap-lg-2 {
    grid-gap: clamp(.5rem, calc(.5rem + (1vw - .225rem) * .12820513), .625rem) !important;
    gap: clamp(.5rem, calc(.5rem + (1vw - .225rem) * .12820513), .625rem) !important;
    grid-gap: var(--mw-space-2) !important;
    gap: var(--mw-space-2) !important
  }

  .mw-gap-lg-3 {
    grid-gap: clamp(.75rem, calc(.75rem + (1vw - .225rem) * .19230769), .9375rem) !important;
    gap: clamp(.75rem, calc(.75rem + (1vw - .225rem) * .19230769), .9375rem) !important;
    grid-gap: var(--mw-space-3) !important;
    gap: var(--mw-space-3) !important
  }

  .mw-gap-lg-4 {
    grid-gap: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem) !important;
    gap: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem) !important;
    grid-gap: var(--mw-space-4) !important;
    gap: var(--mw-space-4) !important
  }

  .mw-gap-lg-6 {
    grid-gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem) !important;
    gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem) !important;
    grid-gap: var(--mw-space-6) !important;
    gap: var(--mw-space-6) !important
  }

  .mw-gap-lg-8 {
    grid-gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem) !important;
    gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem) !important;
    grid-gap: var(--mw-space-8) !important;
    gap: var(--mw-space-8) !important
  }

  .mw-gap-lg-12 {
    grid-gap: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem) !important;
    gap: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem) !important;
    grid-gap: var(--mw-space-12) !important;
    gap: var(--mw-space-12) !important;
  }

  .mw-gap-lg-16 {
    grid-gap: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem) !important;
    gap: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem) !important;
    grid-gap: var(--mw-space-16) !important;
    gap: var(--mw-space-16) !important
  }

  .mw-gap-lg-24 {
    grid-gap: clamp(6rem, calc(6rem + (1vw - .225rem) * 1.53846154), 7.5rem) !important;
    gap: clamp(6rem, calc(6rem + (1vw - .225rem) * 1.53846154), 7.5rem) !important;
    grid-gap: var(--mw-space-24) !important;
    gap: var(--mw-space-24) !important
  }

  .mw-gap-lg-32 {
    grid-gap: clamp(8rem, calc(8rem + (1vw - .225rem) * 2.05128205), 10rem) !important;
    gap: clamp(8rem, calc(8rem + (1vw - .225rem) * 2.05128205), 10rem) !important;
    grid-gap: var(--mw-space-32) !important;
    gap: var(--mw-space-32) !important
  }
}

@media(min-width: 1440px) {
  .mw-gap-2xl-0 {
    grid-gap: 0 !important;
    gap: 0 !important
  }

  .mw-gap-2xl-1 {
    grid-gap: clamp(.25rem, calc(.25rem + (1vw - .225rem) * .06410256), .3125rem) !important;
    gap: clamp(.25rem, calc(.25rem + (1vw - .225rem) * .06410256), .3125rem) !important;
    grid-gap: var(--mw-space-1) !important;
    gap: var(--mw-space-1) !important
  }

  .mw-gap-2xl-2 {
    grid-gap: clamp(.5rem, calc(.5rem + (1vw - .225rem) * .12820513), .625rem) !important;
    gap: clamp(.5rem, calc(.5rem + (1vw - .225rem) * .12820513), .625rem) !important;
    grid-gap: var(--mw-space-2) !important;
    gap: var(--mw-space-2) !important
  }

  .mw-gap-2xl-3 {
    grid-gap: clamp(.75rem, calc(.75rem + (1vw - .225rem) * .19230769), .9375rem) !important;
    gap: clamp(.75rem, calc(.75rem + (1vw - .225rem) * .19230769), .9375rem) !important;
    grid-gap: var(--mw-space-3) !important;
    gap: var(--mw-space-3) !important
  }

  .mw-gap-2xl-4 {
    grid-gap: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem) !important;
    gap: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem) !important;
    grid-gap: var(--mw-space-4) !important;
    gap: var(--mw-space-4) !important
  }

  .mw-gap-2xl-6 {
    grid-gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem) !important;
    gap: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem) !important;
    grid-gap: var(--mw-space-6) !important;
    gap: var(--mw-space-6) !important
  }

  .mw-gap-2xl-8 {
    grid-gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem) !important;
    gap: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem) !important;
    grid-gap: var(--mw-space-8) !important;
    gap: var(--mw-space-8) !important
  }

  .mw-gap-2xl-12 {
    grid-gap: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem) !important;
    gap: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem) !important;
    grid-gap: var(--mw-space-12) !important;
    gap: var(--mw-space-12) !important
  }

  .mw-gap-2xl-16 {
    grid-gap: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem) !important;
    gap: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem) !important;
    grid-gap: var(--mw-space-16) !important;
    gap: var(--mw-space-16) !important
  }

  .mw-gap-2xl-24 {
    grid-gap: clamp(6rem, calc(6rem + (1vw - .225rem) * 1.53846154), 7.5rem) !important;
    gap: clamp(6rem, calc(6rem + (1vw - .225rem) * 1.53846154), 7.5rem) !important;
    grid-gap: var(--mw-space-24) !important;
    gap: var(--mw-space-24) !important
  }

  .mw-gap-2xl-32 {
    grid-gap: clamp(8rem, calc(8rem + (1vw - .225rem) * 2.05128205), 10rem) !important;
    gap: clamp(8rem, calc(8rem + (1vw - .225rem) * 2.05128205), 10rem) !important;
    grid-gap: var(--mw-space-32) !important;
    gap: var(--mw-space-32) !important
  }
}


.feature-icons-items {
  list-style: none;
  justify-content: space-evenly;
  margin-bottom: var(--mw-space-6);
  padding: 0;

  @media (min-width: 768px) {

    display: flex;
    flex-wrap: wrap;
    grid-gap: $size-32;
    gap: $size-32;

  }

  @media (min-width: 992px) {

    margin-bottom: 0;

  }
}


.feature-icon-item {

  display: flex;
  align-items: center;
  margin-right: $size-48;
  margin-bottom: $size-48;
  
  @media (min-width: 768px) {

    margin-bottom: 0;
    margin-right: 0;
    padding-right: $size-12;
    padding-left: $size-12;
    flex: 1 1;
    justify-content: center;

}

}



.feature-icon-item > div:first-child {
  border-radius: 100px;
  padding: $size-12;
  background-color: $color-content-link;
}

.feature-icon-item:last-child {
  margin-bottom: 0;
}

.feature-icon-item.feature-icon-item-green {
  margin-right: 0;
}

.feature-icon-item.feature-icon-item-green > div:first-child {
  padding: $size-12;
}

@media (min-width: 768px) {
  .feature-icon-item.feature-icon-item-green > div:first-child {
    padding: $size-20;
  }
}

.feature-icon-span svg {
  width: 24px;
  height: 24px;
}

@media (min-width: 768px) {
  .feature-icon-span svg {
    width: 32px;
    height: 32px;
  }
}

.feature-icon-item.feature-icon-item-green .feature-icon-span svg {
  color: $color-forest-green;
}

.mw-body-3-bold {
  font-weight: $font-weight-semi-bold;
}

.tapestry-wrapper {
  margin: 0 auto;
  padding-bottom: $size-16;
  position: relative;
  z-index: 1;
}

.tapestry-wrapper > :first-child {
  background-color: $color-bright-orange;
  background-position: bottom;
  background-repeat: no-repeat;
  border-radius: $radius-custom;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.tapestry-wrapper > :last-child {
  overflow: hidden;
  padding: 0 !important;
  background-color: $color-white;
  border-radius: $radius-custom;
  color: $color-content-link;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $size-16;
  z-index: 2;

  @media (min-width: 768px) {

    padding: $size-32;

  }
}

.tapestry-card-content {
  margin: 0 auto;
  width: 100%;
}

.fees-table {
  background: $color-white;
  border-radius: $radius-custom;
  font-size: $font-size-14;
  overflow: auto hidden;
  border-collapse: separate;
  border-spacing: 0;

  & h4, h2 {
    color: $color-forest-green;
  }

  & h4 {
    font-size: $font-size-18;
    font-weight: $font-weight-semi-bold;
    letter-spacing: -.014em;
    line-height: 135%;
  }
}


.fees-table tbody {
  display: inline-table;
  width: 100%;
}

.fees-table h2 {
  font-size: max(1.1375rem, min(calc(1rem + 1vw), 1.625rem));;
  font-size: $font-size-20;
  font-weight: $font-weight-semi-bold;
  letter-spacing: -.02em;
  line-height: 122%;
  margin-bottom: 0;

  @media(min-width: 768px) {
    font-size: $font-size-26;
  }
}

.fees-table .separator {
  border-top: 1px solid #0e0f0c1f;
  padding-top: $size-32
}

.fees-table td {
  padding: $size-8 $size-8;

  @media(min-width: 576px) {

    padding: $size-8 $size-16;

  }

  @media(min-width: 768px) {

    padding: $size-24 $size-32

  }
}


.fees-table td:first-child {
  text-align: left;
}

.fees-table td:first-child header {
  color: $color-content-link;
  font-weight: $font-weight-semi-bold
}

.fees-table td:first-child p {
  margin-bottom: 0
}

.fees-table td:first-child .tw-icon {
  color: var(--color-forest-green)
}

.fees-table td:nth-child(3) {
  background: $color-content-link;
  color: $color-forest-green;
  min-width: 80px;

  @media(min-width: 768px) {

    min-width: 180px

  }
}

.fees-table tr {
  border-top: 1px solid $color-border-overlay;
}

.fees-table tr:first-child td:last-child, .fees-table tr:last-child td:last-child {
  position: relative
}

.fees-table tr:first-child td:last-child:before, .fees-table tr:last-child td:last-child:before {
  background: red;
  border-radius: $radius-custom $radius-custom 0 0;
  content: "";
  height: $size-12;
  left: 0;
  position: absolute;
  top: -12px;
  width: 100%
}

.fees-table tr:last-child td:last-child {
  position: relative
}

.fees-table tr:last-child td:last-child:before {
  border-radius: 0 0 $radius-custom $radius-custom;
  bottom: -12px;
  top: auto
}

.table-tooltip {
  color: $color-dark;
}

.bg-white {
  background-color: white;
}

.bg-neutral {
  background-color: rgba(238, 247, 238, 0.62);
}

.description-regular {
  font-size: max(.875rem, min(calc(.51136rem + 1.81818vw), 1rem));
}

.description-small {
  font-size: max(.675rem, min(calc(.41136rem + 1.81818vw), 0.75rem));
}

.flag-icon {
  width: 71px;
  height: 71px;
}


.olx-domain-name {

  font-weight: $font-weight-semi-bold;
  font-size: .75rem;

  @media(min-width: 768px) {

    font-size: .95rem;

  }
}


/* Скрываем стандартный радио инпут */
.country-flag input[type="radio"] {
  display: none;

}


.country-flag-label {
  padding: 16px;

  @media(min-width: 768px) {

    padding: 23px;

  }
}

.country-flag input[type="radio"] + label {
  cursor: pointer;
}


.country-flag input[type="radio"]:hover + label {
  background-color: $color-content-link;
  border-radius: 0.5rem;
}

.country-flag input[type="radio"]:checked + label {
  font-weight: bold;
  color: $color-content-link;
  background-color: $color-forest-green;
  border-radius: 0.5rem;
  border-bottom: none;

}

.radio-img {
  margin-bottom: 0.5rem;
  width: 44px;
  height: 44px;

  @media(min-width: 768px) {

    width: 53px;
    height: 53px;

  }
}

.testimonial-header-container, .testimonials-container {
  margin-bottom: 72px;
}



.testimonial-description {
  margin-bottom: $size-8;
  font-weight: $font-weight-regular;
  line-height: 26px;
  letter-spacing: $letter-spacing-xxs;
  font-size: max(1rem, min(calc(.63636rem + 1.81818vw), 1.125rem));

  @media(min-width: 992px) {
    line-height: 28px;
    font-size: max(1.125rem, min(calc(.58114rem + .87719vw), 1.25rem))

  }
}


.testimonial-text, .mission-text {

  line-height: 28px;
  font-weight: $font-weight-semi-bold;
  letter-spacing: $letter-spacing-negative-xs;
  margin-bottom: $size-24;
  font-size: max(1.125rem, min(calc(.76136rem + 1.81818vw), 1.25rem));

  @media(min-width: 992px) {

    line-height: $size-32;
    letter-spacing: $letter-spacing-negative-md;
    font-size: max(1.25rem, min(calc(.16228rem + 1.75439vw), 1.5rem))

  }

  @media(min-width: 1200px) {
    font-size: max(1.5rem, min(calc(1.28214rem + .28571vw), 1.625rem))
  }
}

.testimonial-img {
  color: $color-dark;
  width: 44px;
  height: 44px;
  margin-right: 3px;
}

.testimonial-link {
  color: $color-dark;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: $color-dark;
    font-weight: bold;
  }
}

blockquote footer:before {
  content: "\2014 \00A0";
}

blockquote footer {
  display: block;
  font-size: .875rem;
  line-height: 1.5;

}

.section--rounded {
  border-radius: $radius-custom;
}


.mw-section--contained {
  border-radius: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem);
  border-radius: var(--mw-radius-4);
  padding-top: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem);
  padding-top: var(--mw-space-16);
  padding-bottom: clamp(4rem, calc(4rem + (1vw - .225rem) * 1.02564103), 5rem);
  padding-bottom: var(--mw-space-16);
  margin-top: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem);
  margin-top: var(--mw-space-4);
  margin-bottom: clamp(1rem, calc(1rem + (1vw - .225rem) * .25641026), 1.25rem);
  margin-bottom: var(--mw-space-4);

  @media(min-width: 480px) {
    padding-left: clamp(1.5rem, calc(1.5rem + (1vw - .225rem) * .38461538), 1.875rem);

  }

  @media(min-width: 768px) {
    padding-left: clamp(2rem, calc(2rem + (1vw - .225rem) * .51282051), 2.5rem)
  }

  @media(min-width: 1440px) {
    padding-left: clamp(3rem, calc(3rem + (1vw - .225rem) * .76923077), 3.75rem)
  }


}

.section--margin {
  margin-top: $size-96;
  margin-bottom: $size-96;
}


.mw-section--contained .mw-row {

  align-items: center
}


.mission-image-container {
  margin-top: -85px;

  @media(min-width: 576px) {
    margin-top: -95px
  }

  @media(min-width: 768px) {
    margin-top: -105px
  }

  @media(min-width: 992px) {
    margin-top: -125px
  }

}

.mission-image {

  width: 75%;

  @media(min-width: 768px) {
    width: 410px
  }
  @media(min-width: 1200px) {
    width: 450px;
  }

}

.mission-title-container {
  margin-bottom: 32px;
  @media(min-width: 992px) {
    margin-bottom: 48px;
  }

}

.mission-text {
  color: $color-content-link;
}

.mission-title {
  color: $color-content-link;
  line-height: .85;
  letter-spacing: 0;
  margin-bottom: 32px;
  font-weight: 900;
  font-size: clamp(2.625rem, calc((42 / 16 * 1rem) + (1vw - .2rem) * (100 * ((56 / 16) - (42 / 16)) / (26.875 - 20))), 3.5rem);

  @media(min-width: 992px) {
    font-size: clamp(3.5rem, calc((56 / 16 * 1rem) + (1vw - .62rem) * (100 * ((96 / 16) - (56 / 16)) / (76.25 - 62))), 6rem);
  }

  @media(min-width: 1200px) {
    font-size: clamp(6rem, calc((96 / 16 * 1rem) + (1vw - .7625rem) * (100 * ((126 / 16) - (96 / 16)) / (120 - 76.25))), 7.875rem)
  }

}


.custom-slider {
  overflow: hidden;
  position: relative;
  border-radius: 0.7rem;
  //min-height: 40vw;
  height: 52vw;

  @media(min-width: 1440px) {
    height: 39vw;
  }


}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  //background-size: cover;
  //background-repeat: no-repeat;
  transition: transform 1s; /* Плавность перехода */
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  //border-radius: 0.7rem;
}

.slide1 {

  animation-delay: -5s; /* Половина времени анимации, чтобы анимации чередовались */
}

.slide2 {

}

@keyframes slideAnimation {
  0%, 100% {
    transform: translateX(100%);
  }
  5%, 45% {
    transform: translateX(0);
  }
  50%, 95% {
    transform: translateX(-100%);
  }
}

#slide1 {
  transform: translateX(0%); /* Первый слайд изначально виден */
}

#slide2 {
  transform: translateX(100%); /* Второй слайд ожидает справа */
}



